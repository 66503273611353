import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "Which software can I use to edit my video recordings?",
  "path": "/Frequently_Asked_Question/Video_Editing_with_Davinci_Resolve/",
  "dateChanged": "2020-12-03",
  "author": "Mike Polinowski",
  "excerpt": "How do I create a time lapse image from my photo series?",
  "image": "./FAQ_SearchThumb.png",
  "social": "/images/Search/FAQ_SearchThumb.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_FAQs_white.webp",
  "chapter": "FAQs",
  "faq": "Full HD Cameras"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <SEOHelmet title='FAQs - Which software can I use to edit my video recordings?' dateChanged='2020-12-03' author='Mike Polinowski' tag='INSTAR IP Camera' description='How do I create a time lapse image from my photo series?' image='/images/Search/FAQ_SearchThumb.png' twitter='/images/Search/FAQ_SearchThumb.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Frequently_Asked_Question/Video_Editing_with_Davinci_Resolve/' locationFR='/fr/Frequently_Asked_Question/Video_Editing_with_Davinci_Resolve/' crumbLabel="FAQs" mdxType="BreadCrumbs" />
    <p><strong parentName="p">{`Q`}</strong>{`: I would like to cut my alarm recordings and photo series into films. Which software do you recommend?`}</p>
    <p><strong parentName="p">{`A`}</strong>{`: One `}<strong parentName="p">{`free software`}</strong>{` that you can use is `}<a parentName="p" {...{
        "href": "https://www.blackmagicdesign.com/products/davinciresolve/"
      }}>{`Davinci Resolve`}</a>{` from Blackmagic Design. You can download this software for Windows, macOS or LINUX from the manufacturer's site. In the following we would like to show you how you can use the software to create a time-lapse shot from your photo series. The cutting together of videos is done in the same way.`}</p>
    <p>{`Start the software and click on `}<strong parentName="p">{`New Project`}</strong>{` to start a new project:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/776827057fe0703c58bd42b8d89da054/47311/Davinci_Resolve_01.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "73.47826086956522%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAQAF/8QAFAEBAAAAAAAAAAAAAAAAAAAAAP/aAAwDAQACEAMQAAAByyQmP//EABQQAQAAAAAAAAAAAAAAAAAAACD/2gAIAQEAAQUCX//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8BP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8BP//EABQQAQAAAAAAAAAAAAAAAAAAACD/2gAIAQEABj8CX//EABUQAQEAAAAAAAAAAAAAAAAAACAh/9oACAEBAAE/IYv/2gAMAwEAAgADAAAAEGsv/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPxA//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPxA//8QAGhABAQEAAwEAAAAAAAAAAAAAAREAECExUf/aAAgBAQABPxBIId6DofDgF803/9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/776827057fe0703c58bd42b8d89da054/e4706/Davinci_Resolve_01.avif 230w", "/en/static/776827057fe0703c58bd42b8d89da054/d1af7/Davinci_Resolve_01.avif 460w", "/en/static/776827057fe0703c58bd42b8d89da054/7f308/Davinci_Resolve_01.avif 920w", "/en/static/776827057fe0703c58bd42b8d89da054/d8ca6/Davinci_Resolve_01.avif 1080w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/776827057fe0703c58bd42b8d89da054/a0b58/Davinci_Resolve_01.webp 230w", "/en/static/776827057fe0703c58bd42b8d89da054/bc10c/Davinci_Resolve_01.webp 460w", "/en/static/776827057fe0703c58bd42b8d89da054/966d8/Davinci_Resolve_01.webp 920w", "/en/static/776827057fe0703c58bd42b8d89da054/260c2/Davinci_Resolve_01.webp 1080w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/776827057fe0703c58bd42b8d89da054/e83b4/Davinci_Resolve_01.jpg 230w", "/en/static/776827057fe0703c58bd42b8d89da054/e41a8/Davinci_Resolve_01.jpg 460w", "/en/static/776827057fe0703c58bd42b8d89da054/4d836/Davinci_Resolve_01.jpg 920w", "/en/static/776827057fe0703c58bd42b8d89da054/47311/Davinci_Resolve_01.jpg 1080w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/776827057fe0703c58bd42b8d89da054/4d836/Davinci_Resolve_01.jpg",
              "alt": "Windows Time Lapse Creator",
              "title": "Windows Time Lapse Creator",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Now we want to set the default settings for the project. You can do this in the DavinciResolve Software menu under the item `}<strong parentName="p">{`Preferences...`}</strong>{`:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "257px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/ec5f98b36ce9fdbd00db165e1f7708e0/a6c19/Davinci_Resolve_02.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "97.3913043478261%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAATABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAAEC/8QAFgEBAQEAAAAAAAAAAAAAAAAAAAIB/9oADAMBAAIQAxAAAAHdmqnTBspOgf/EABsQAAICAwEAAAAAAAAAAAAAAAABEUECEBIh/9oACAEBAAEFAvBNHSLggvHX/8QAFhEAAwAAAAAAAAAAAAAAAAAAABAh/9oACAEDAQE/ASv/xAAXEQADAQAAAAAAAAAAAAAAAAAAEBEh/9oACAECAQE/AaY//8QAGRAAAgMBAAAAAAAAAAAAAAAAADEBEEEg/9oACAEBAAY/AjDKUC4//8QAHRAAAgIBBQAAAAAAAAAAAAAAABEBQZEhMVFhcf/aAAgBAQABPyFUSI1p6dmRtogWyTjgmZsSbZcn/9oADAMBAAIAAwAAABDvB77/xAAXEQADAQAAAAAAAAAAAAAAAAABESAx/9oACAEDAQE/EERih//EABYRAQEBAAAAAAAAAAAAAAAAAAEgMf/aAAgBAgEBPxBDqx//xAAdEAEAAwACAwEAAAAAAAAAAAABABExIVFBYYGx/9oACAEBAAE/EG3jTlJkCpHoiVqp3z7xpBUvDPyC4Pgy9b4Gi/CEVLe4nIWu5bu73P/Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/ec5f98b36ce9fdbd00db165e1f7708e0/e4706/Davinci_Resolve_02.avif 230w", "/en/static/ec5f98b36ce9fdbd00db165e1f7708e0/f0fcf/Davinci_Resolve_02.avif 257w"],
              "sizes": "(max-width: 257px) 100vw, 257px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/ec5f98b36ce9fdbd00db165e1f7708e0/a0b58/Davinci_Resolve_02.webp 230w", "/en/static/ec5f98b36ce9fdbd00db165e1f7708e0/a93a3/Davinci_Resolve_02.webp 257w"],
              "sizes": "(max-width: 257px) 100vw, 257px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/ec5f98b36ce9fdbd00db165e1f7708e0/e83b4/Davinci_Resolve_02.jpg 230w", "/en/static/ec5f98b36ce9fdbd00db165e1f7708e0/a6c19/Davinci_Resolve_02.jpg 257w"],
              "sizes": "(max-width: 257px) 100vw, 257px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/ec5f98b36ce9fdbd00db165e1f7708e0/a6c19/Davinci_Resolve_02.jpg",
              "alt": "Windows Time Lapse Creator",
              "title": "Windows Time Lapse Creator",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`For our timelapse recording we want each image to be shown for only one `}<strong parentName="p">{`frame`}</strong>{` long:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/d27fa324dee7a60590b598eefe4bd77a/56d85/Davinci_Resolve_03.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "84.78260869565217%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAARABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAAEF/8QAFQEBAQAAAAAAAAAAAAAAAAAAAAH/2gAMAwEAAhADEAAAAcsAEpQR/8QAFBABAAAAAAAAAAAAAAAAAAAAMP/aAAgBAQABBQIf/8QAFBEBAAAAAAAAAAAAAAAAAAAAIP/aAAgBAwEBPwEf/8QAFBEBAAAAAAAAAAAAAAAAAAAAIP/aAAgBAgEBPwEf/8QAFBABAAAAAAAAAAAAAAAAAAAAMP/aAAgBAQAGPwIf/8QAGRAAAgMBAAAAAAAAAAAAAAAAAAEQESBh/9oACAEBAAE/ISuCh5//2gAMAwEAAgADAAAAEBsoP//EABQRAQAAAAAAAAAAAAAAAAAAACD/2gAIAQMBAT8QH//EABQRAQAAAAAAAAAAAAAAAAAAACD/2gAIAQIBAT8QH//EABwQAQADAAIDAAAAAAAAAAAAAAEAETEQIUFhcf/aAAgBAQABPxADWn6RtadICsIblzpVL7gpwaR8T//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/d27fa324dee7a60590b598eefe4bd77a/e4706/Davinci_Resolve_03.avif 230w", "/en/static/d27fa324dee7a60590b598eefe4bd77a/d1af7/Davinci_Resolve_03.avif 460w", "/en/static/d27fa324dee7a60590b598eefe4bd77a/7f308/Davinci_Resolve_03.avif 920w", "/en/static/d27fa324dee7a60590b598eefe4bd77a/c82bd/Davinci_Resolve_03.avif 946w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/d27fa324dee7a60590b598eefe4bd77a/a0b58/Davinci_Resolve_03.webp 230w", "/en/static/d27fa324dee7a60590b598eefe4bd77a/bc10c/Davinci_Resolve_03.webp 460w", "/en/static/d27fa324dee7a60590b598eefe4bd77a/966d8/Davinci_Resolve_03.webp 920w", "/en/static/d27fa324dee7a60590b598eefe4bd77a/30833/Davinci_Resolve_03.webp 946w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/d27fa324dee7a60590b598eefe4bd77a/e83b4/Davinci_Resolve_03.jpg 230w", "/en/static/d27fa324dee7a60590b598eefe4bd77a/e41a8/Davinci_Resolve_03.jpg 460w", "/en/static/d27fa324dee7a60590b598eefe4bd77a/4d836/Davinci_Resolve_03.jpg 920w", "/en/static/d27fa324dee7a60590b598eefe4bd77a/56d85/Davinci_Resolve_03.jpg 946w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/d27fa324dee7a60590b598eefe4bd77a/4d836/Davinci_Resolve_03.jpg",
              "alt": "Windows Time Lapse Creator",
              "title": "Windows Time Lapse Creator",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`In the Media tab (see the tab marked in red at the bottom of the software) you can find the folder where you have saved your images (or videos) and drag it into your project:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/ce9c282f89e800794d762060c6391e95/083ca/Davinci_Resolve_04.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "54.78260869565218%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAALABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAEDAgX/xAAUAQEAAAAAAAAAAAAAAAAAAAAA/9oADAMBAAIQAxAAAAHlTojQg//EABkQAAEFAAAAAAAAAAAAAAAAAAABESAhIv/aAAgBAQABBQKzQ6w//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAFhAAAwAAAAAAAAAAAAAAAAAAACAx/9oACAEBAAY/Air/AP/EABoQAAIDAQEAAAAAAAAAAAAAAAERABCRMWH/2gAIAQEAAT8hJbp2J+9nodp1/9oADAMBAAIAAwAAABCbL//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8QP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8QP//EABkQAQEBAQEBAAAAAAAAAAAAAAEAEVEx4f/aAAgBAQABPxB+jz6jMuW9z9JK62oW/9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/ce9c282f89e800794d762060c6391e95/e4706/Davinci_Resolve_04.avif 230w", "/en/static/ce9c282f89e800794d762060c6391e95/d1af7/Davinci_Resolve_04.avif 460w", "/en/static/ce9c282f89e800794d762060c6391e95/7f308/Davinci_Resolve_04.avif 920w", "/en/static/ce9c282f89e800794d762060c6391e95/e1c99/Davinci_Resolve_04.avif 1380w", "/en/static/ce9c282f89e800794d762060c6391e95/76ea5/Davinci_Resolve_04.avif 1840w", "/en/static/ce9c282f89e800794d762060c6391e95/eb9b6/Davinci_Resolve_04.avif 1926w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/ce9c282f89e800794d762060c6391e95/a0b58/Davinci_Resolve_04.webp 230w", "/en/static/ce9c282f89e800794d762060c6391e95/bc10c/Davinci_Resolve_04.webp 460w", "/en/static/ce9c282f89e800794d762060c6391e95/966d8/Davinci_Resolve_04.webp 920w", "/en/static/ce9c282f89e800794d762060c6391e95/445df/Davinci_Resolve_04.webp 1380w", "/en/static/ce9c282f89e800794d762060c6391e95/78de1/Davinci_Resolve_04.webp 1840w", "/en/static/ce9c282f89e800794d762060c6391e95/7bdb9/Davinci_Resolve_04.webp 1926w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/ce9c282f89e800794d762060c6391e95/e83b4/Davinci_Resolve_04.jpg 230w", "/en/static/ce9c282f89e800794d762060c6391e95/e41a8/Davinci_Resolve_04.jpg 460w", "/en/static/ce9c282f89e800794d762060c6391e95/4d836/Davinci_Resolve_04.jpg 920w", "/en/static/ce9c282f89e800794d762060c6391e95/89b2d/Davinci_Resolve_04.jpg 1380w", "/en/static/ce9c282f89e800794d762060c6391e95/5726f/Davinci_Resolve_04.jpg 1840w", "/en/static/ce9c282f89e800794d762060c6391e95/083ca/Davinci_Resolve_04.jpg 1926w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/ce9c282f89e800794d762060c6391e95/4d836/Davinci_Resolve_04.jpg",
              "alt": "Windows Time Lapse Creator",
              "title": "Windows Time Lapse Creator",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`With a right click you can add the contents of the folder to the project timeline:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/94bd3d11993b0acf6b7c179d7ebf6012/7645c/Davinci_Resolve_05.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "54.78260869565218%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAALABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAIDAQX/xAAUAQEAAAAAAAAAAAAAAAAAAAAA/9oADAMBAAIQAxAAAAHlSdSpgf/EABcQAAMBAAAAAAAAAAAAAAAAAAABECL/2gAIAQEAAQUCZqO//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAFhAAAwAAAAAAAAAAAAAAAAAAACAx/9oACAEBAAY/Air/AP/EABsQAAEEAwAAAAAAAAAAAAAAAAEAETGRICFR/9oACAEBAAE/ISLybQ2nac9OH//aAAwDAQACAAMAAAAQOC//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/ED//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/ED//xAAaEAEBAAMBAQAAAAAAAAAAAAABABEhUWHx/9oACAEBAAE/EDh1PqGDt1fck5Idwt//2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/94bd3d11993b0acf6b7c179d7ebf6012/e4706/Davinci_Resolve_05.avif 230w", "/en/static/94bd3d11993b0acf6b7c179d7ebf6012/d1af7/Davinci_Resolve_05.avif 460w", "/en/static/94bd3d11993b0acf6b7c179d7ebf6012/7f308/Davinci_Resolve_05.avif 920w", "/en/static/94bd3d11993b0acf6b7c179d7ebf6012/e1c99/Davinci_Resolve_05.avif 1380w", "/en/static/94bd3d11993b0acf6b7c179d7ebf6012/76ea5/Davinci_Resolve_05.avif 1840w", "/en/static/94bd3d11993b0acf6b7c179d7ebf6012/38c07/Davinci_Resolve_05.avif 1922w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/94bd3d11993b0acf6b7c179d7ebf6012/a0b58/Davinci_Resolve_05.webp 230w", "/en/static/94bd3d11993b0acf6b7c179d7ebf6012/bc10c/Davinci_Resolve_05.webp 460w", "/en/static/94bd3d11993b0acf6b7c179d7ebf6012/966d8/Davinci_Resolve_05.webp 920w", "/en/static/94bd3d11993b0acf6b7c179d7ebf6012/445df/Davinci_Resolve_05.webp 1380w", "/en/static/94bd3d11993b0acf6b7c179d7ebf6012/78de1/Davinci_Resolve_05.webp 1840w", "/en/static/94bd3d11993b0acf6b7c179d7ebf6012/da98d/Davinci_Resolve_05.webp 1922w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/94bd3d11993b0acf6b7c179d7ebf6012/e83b4/Davinci_Resolve_05.jpg 230w", "/en/static/94bd3d11993b0acf6b7c179d7ebf6012/e41a8/Davinci_Resolve_05.jpg 460w", "/en/static/94bd3d11993b0acf6b7c179d7ebf6012/4d836/Davinci_Resolve_05.jpg 920w", "/en/static/94bd3d11993b0acf6b7c179d7ebf6012/89b2d/Davinci_Resolve_05.jpg 1380w", "/en/static/94bd3d11993b0acf6b7c179d7ebf6012/5726f/Davinci_Resolve_05.jpg 1840w", "/en/static/94bd3d11993b0acf6b7c179d7ebf6012/7645c/Davinci_Resolve_05.jpg 1922w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/94bd3d11993b0acf6b7c179d7ebf6012/4d836/Davinci_Resolve_05.jpg",
              "alt": "Windows Time Lapse Creator",
              "title": "Windows Time Lapse Creator",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Give the timeline a name here:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "531px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/5c96673e27be4fa1592147fa4d9f3de2/135f3/Davinci_Resolve_06.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "54.347826086956516%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAALABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAAEF/8QAFAEBAAAAAAAAAAAAAAAAAAAAAP/aAAwDAQACEAMQAAABywRR/8QAFBABAAAAAAAAAAAAAAAAAAAAIP/aAAgBAQABBQJf/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAFBABAAAAAAAAAAAAAAAAAAAAIP/aAAgBAQAGPwJf/8QAGBAAAgMAAAAAAAAAAAAAAAAAAAEQMUH/2gAIAQEAAT8huxpYoyGf/9oADAMBAAIAAwAAABDoL//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8QP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8QP//EABoQAQACAwEAAAAAAAAAAAAAAAEAESExUZH/2gAIAQEAAT8QMzaux2FK29gFal8HkFqLM//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/5c96673e27be4fa1592147fa4d9f3de2/e4706/Davinci_Resolve_06.avif 230w", "/en/static/5c96673e27be4fa1592147fa4d9f3de2/d1af7/Davinci_Resolve_06.avif 460w", "/en/static/5c96673e27be4fa1592147fa4d9f3de2/2cd78/Davinci_Resolve_06.avif 531w"],
              "sizes": "(max-width: 531px) 100vw, 531px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/5c96673e27be4fa1592147fa4d9f3de2/a0b58/Davinci_Resolve_06.webp 230w", "/en/static/5c96673e27be4fa1592147fa4d9f3de2/bc10c/Davinci_Resolve_06.webp 460w", "/en/static/5c96673e27be4fa1592147fa4d9f3de2/a33a1/Davinci_Resolve_06.webp 531w"],
              "sizes": "(max-width: 531px) 100vw, 531px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/5c96673e27be4fa1592147fa4d9f3de2/e83b4/Davinci_Resolve_06.jpg 230w", "/en/static/5c96673e27be4fa1592147fa4d9f3de2/e41a8/Davinci_Resolve_06.jpg 460w", "/en/static/5c96673e27be4fa1592147fa4d9f3de2/135f3/Davinci_Resolve_06.jpg 531w"],
              "sizes": "(max-width: 531px) 100vw, 531px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/5c96673e27be4fa1592147fa4d9f3de2/135f3/Davinci_Resolve_06.jpg",
              "alt": "Windows Time Lapse Creator",
              "title": "Windows Time Lapse Creator",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`As you can see below, the default setting we made at the beginning is now applied and each image is exactly one frame "long":`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/c6777b6b2ae0b93a8b5d474006dd315b/1047b/Davinci_Resolve_07.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "54.347826086956516%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAALABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAEEBf/EABQBAQAAAAAAAAAAAAAAAAAAAAD/2gAMAwEAAhADEAAAAcwSKyQP/8QAGhAAAgIDAAAAAAAAAAAAAAAAAQIDEgAQEf/aAAgBAQABBQJ+0iNWMi4Tv//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8BP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8BP//EABoQAAICAwAAAAAAAAAAAAAAAAABESAhIjH/2gAIAQEABj8CUG2Ucp//xAAbEAEAAgIDAAAAAAAAAAAAAAABABEQIUFRcf/aAAgBAQABPyGy0j7Euv1XGumRNHGf/9oADAMBAAIAAwAAABD4L//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8QP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8QP//EABsQAAIDAAMAAAAAAAAAAAAAAAERACExgZHB/9oACAEBAAE/ELUg2WD2X3AWi7juKWJexR9AwOYSVsc//9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/c6777b6b2ae0b93a8b5d474006dd315b/e4706/Davinci_Resolve_07.avif 230w", "/en/static/c6777b6b2ae0b93a8b5d474006dd315b/d1af7/Davinci_Resolve_07.avif 460w", "/en/static/c6777b6b2ae0b93a8b5d474006dd315b/7f308/Davinci_Resolve_07.avif 920w", "/en/static/c6777b6b2ae0b93a8b5d474006dd315b/e1c99/Davinci_Resolve_07.avif 1380w", "/en/static/c6777b6b2ae0b93a8b5d474006dd315b/76ea5/Davinci_Resolve_07.avif 1840w", "/en/static/c6777b6b2ae0b93a8b5d474006dd315b/e0654/Davinci_Resolve_07.avif 1924w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/c6777b6b2ae0b93a8b5d474006dd315b/a0b58/Davinci_Resolve_07.webp 230w", "/en/static/c6777b6b2ae0b93a8b5d474006dd315b/bc10c/Davinci_Resolve_07.webp 460w", "/en/static/c6777b6b2ae0b93a8b5d474006dd315b/966d8/Davinci_Resolve_07.webp 920w", "/en/static/c6777b6b2ae0b93a8b5d474006dd315b/445df/Davinci_Resolve_07.webp 1380w", "/en/static/c6777b6b2ae0b93a8b5d474006dd315b/78de1/Davinci_Resolve_07.webp 1840w", "/en/static/c6777b6b2ae0b93a8b5d474006dd315b/7e82b/Davinci_Resolve_07.webp 1924w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/c6777b6b2ae0b93a8b5d474006dd315b/e83b4/Davinci_Resolve_07.jpg 230w", "/en/static/c6777b6b2ae0b93a8b5d474006dd315b/e41a8/Davinci_Resolve_07.jpg 460w", "/en/static/c6777b6b2ae0b93a8b5d474006dd315b/4d836/Davinci_Resolve_07.jpg 920w", "/en/static/c6777b6b2ae0b93a8b5d474006dd315b/89b2d/Davinci_Resolve_07.jpg 1380w", "/en/static/c6777b6b2ae0b93a8b5d474006dd315b/5726f/Davinci_Resolve_07.jpg 1840w", "/en/static/c6777b6b2ae0b93a8b5d474006dd315b/1047b/Davinci_Resolve_07.jpg 1924w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/c6777b6b2ae0b93a8b5d474006dd315b/4d836/Davinci_Resolve_07.jpg",
              "alt": "Windows Time Lapse Creator",
              "title": "Windows Time Lapse Creator",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`And now they have almost reached the end. All we have to do now is export the timeline in a video file. This is done again in the software menu under `}<strong parentName="p">{`File`}</strong>{` ("File") and `}<strong parentName="p">{`Quick Export...`}</strong>{`:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "700px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/703de179e775ab1ee033d8b1a4c84401/29d31/Davinci_Resolve_08.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "94.34782608695652%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAATABQDASIAAhEBAxEB/8QAGQABAAMBAQAAAAAAAAAAAAAAAAIDBAEF/8QAFQEBAQAAAAAAAAAAAAAAAAAAAAH/2gAMAwEAAhADEAAAAYV9qMagezEMIr//xAAcEAABBQADAAAAAAAAAAAAAAACAAEDBDEREzL/2gAIAQEAAQUCgJyayZAu+RV8tsuHVbLPot//xAAUEQEAAAAAAAAAAAAAAAAAAAAg/9oACAEDAQE/AR//xAAUEQEAAAAAAAAAAAAAAAAAAAAg/9oACAECAQE/AR//xAAbEAACAQUAAAAAAAAAAAAAAAAAARACERIhMv/aAAgBAQAGPwLbFizpjFaGUx//xAAcEAACAgIDAAAAAAAAAAAAAAAAARFBIaExYfH/2gAIAQEAAT8hZJ3mx+zVK4PeNoqqOo2DXEiCP//aAAwDAQACAAMAAAAQWweA/8QAFBEBAAAAAAAAAAAAAAAAAAAAIP/aAAgBAwEBPxAf/8QAFBEBAAAAAAAAAAAAAAAAAAAAIP/aAAgBAgEBPxAf/8QAHhABAAICAQUAAAAAAAAAAAAAAQAxESFRYXGRobH/2gAIAQEAAT8QcUAA2qoVn2DRcZ4jlM688SncnXedfH4fyAQxpR7gOGCf/9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/703de179e775ab1ee033d8b1a4c84401/e4706/Davinci_Resolve_08.avif 230w", "/en/static/703de179e775ab1ee033d8b1a4c84401/d1af7/Davinci_Resolve_08.avif 460w", "/en/static/703de179e775ab1ee033d8b1a4c84401/70e80/Davinci_Resolve_08.avif 700w"],
              "sizes": "(max-width: 700px) 100vw, 700px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/703de179e775ab1ee033d8b1a4c84401/a0b58/Davinci_Resolve_08.webp 230w", "/en/static/703de179e775ab1ee033d8b1a4c84401/bc10c/Davinci_Resolve_08.webp 460w", "/en/static/703de179e775ab1ee033d8b1a4c84401/426ac/Davinci_Resolve_08.webp 700w"],
              "sizes": "(max-width: 700px) 100vw, 700px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/703de179e775ab1ee033d8b1a4c84401/e83b4/Davinci_Resolve_08.jpg 230w", "/en/static/703de179e775ab1ee033d8b1a4c84401/e41a8/Davinci_Resolve_08.jpg 460w", "/en/static/703de179e775ab1ee033d8b1a4c84401/29d31/Davinci_Resolve_08.jpg 700w"],
              "sizes": "(max-width: 700px) 100vw, 700px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/703de179e775ab1ee033d8b1a4c84401/29d31/Davinci_Resolve_08.jpg",
              "alt": "Windows Time Lapse Creator",
              "title": "Windows Time Lapse Creator",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`In the export dialogue, specify the quality you want the video to have and click on `}<strong parentName="p">{`Export`}</strong>{`:follows again in the software menu under `}<strong parentName="p">{`File`}</strong>{` ("File") and `}<strong parentName="p">{`Quick Export...`}</strong>{`:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "450px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/e2bbb2ea6f668232019954894aee8e2f/20e5d/Davinci_Resolve_09.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.52173913043479%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAALABQDASIAAhEBAxEB/8QAGAAAAgMAAAAAAAAAAAAAAAAAAAIBAwX/xAAVAQEBAAAAAAAAAAAAAAAAAAAAAv/aAAwDAQACEAMQAAABy4GELBX/xAAWEAADAAAAAAAAAAAAAAAAAAAAESD/2gAIAQEAAQUCFP8A/8QAFREBAQAAAAAAAAAAAAAAAAAAABL/2gAIAQMBAT8BS//EABURAQEAAAAAAAAAAAAAAAAAAAAR/9oACAECAQE/AVf/xAAUEAEAAAAAAAAAAAAAAAAAAAAg/9oACAEBAAY/Al//xAAZEAACAwEAAAAAAAAAAAAAAAAAARARIVH/2gAIAQEAAT8hepQqODWn/9oADAMBAAIAAwAAABB/D//EABYRAQEBAAAAAAAAAAAAAAAAAAEAEf/aAAgBAwEBPxANbV//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/EB//xAAcEAACAgIDAAAAAAAAAAAAAAABEQAhEDFRYXH/2gAIAQEAAT8QdwtBbMTq/Yb8YACpAAghuf/Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/e2bbb2ea6f668232019954894aee8e2f/e4706/Davinci_Resolve_09.avif 230w", "/en/static/e2bbb2ea6f668232019954894aee8e2f/e46ac/Davinci_Resolve_09.avif 450w"],
              "sizes": "(max-width: 450px) 100vw, 450px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/e2bbb2ea6f668232019954894aee8e2f/a0b58/Davinci_Resolve_09.webp 230w", "/en/static/e2bbb2ea6f668232019954894aee8e2f/8626f/Davinci_Resolve_09.webp 450w"],
              "sizes": "(max-width: 450px) 100vw, 450px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/e2bbb2ea6f668232019954894aee8e2f/e83b4/Davinci_Resolve_09.jpg 230w", "/en/static/e2bbb2ea6f668232019954894aee8e2f/20e5d/Davinci_Resolve_09.jpg 450w"],
              "sizes": "(max-width: 450px) 100vw, 450px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/e2bbb2ea6f668232019954894aee8e2f/20e5d/Davinci_Resolve_09.jpg",
              "alt": "Windows Time Lapse Creator",
              "title": "Windows Time Lapse Creator",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Select a location where you want to save the video:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "347px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/6ba62777f05405cdb42a50c6334d9e08/4d0aa/Davinci_Resolve_10.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "52.17391304347826%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAKABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAAIF/8QAFQEBAQAAAAAAAAAAAAAAAAAAAAH/2gAMAwEAAhADEAAAAdlUoSr/xAAZEAACAwEAAAAAAAAAAAAAAAAAAQIQETH/2gAIAQEAAQUCwxW+RP/EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8BP//EABURAQEAAAAAAAAAAAAAAAAAAAAB/9oACAECAQE/AVf/xAAUEAEAAAAAAAAAAAAAAAAAAAAg/9oACAEBAAY/Al//xAAaEAEAAgMBAAAAAAAAAAAAAAABABEQITHw/9oACAEBAAE/IUeIdpQ8wbVwAaAn/9oADAMBAAIAAwAAABBP7//EABcRAQADAAAAAAAAAAAAAAAAAAEAESH/2gAIAQMBAT8Qc2DZc//EABcRAQEBAQAAAAAAAAAAAAAAAAERACH/2gAIAQIBAT8QOswjN//EABwQAQEBAAEFAAAAAAAAAAAAAAERACEQMVFhkf/aAAgBAQABPxBCjQ9Vjd30mADUfJz0AIESI5eALeCb/9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/6ba62777f05405cdb42a50c6334d9e08/e4706/Davinci_Resolve_10.avif 230w", "/en/static/6ba62777f05405cdb42a50c6334d9e08/6f577/Davinci_Resolve_10.avif 347w"],
              "sizes": "(max-width: 347px) 100vw, 347px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/6ba62777f05405cdb42a50c6334d9e08/a0b58/Davinci_Resolve_10.webp 230w", "/en/static/6ba62777f05405cdb42a50c6334d9e08/2baae/Davinci_Resolve_10.webp 347w"],
              "sizes": "(max-width: 347px) 100vw, 347px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/6ba62777f05405cdb42a50c6334d9e08/e83b4/Davinci_Resolve_10.jpg 230w", "/en/static/6ba62777f05405cdb42a50c6334d9e08/4d0aa/Davinci_Resolve_10.jpg 347w"],
              "sizes": "(max-width: 347px) 100vw, 347px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/6ba62777f05405cdb42a50c6334d9e08/4d0aa/Davinci_Resolve_10.jpg",
              "alt": "Windows Time Lapse Creator",
              "title": "Windows Time Lapse Creator",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`And wait until the video render has run through:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "403px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/a1a1c9ea0582ab55121855fe9dee1d23/c80d3/Davinci_Resolve_11.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "73.04347826086956%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAECBf/EABQBAQAAAAAAAAAAAAAAAAAAAAD/2gAMAwEAAhADEAAAAebkIo//xAAWEAADAAAAAAAAAAAAAAAAAAARIEH/2gAIAQEAAQUCgX//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAUEAEAAAAAAAAAAAAAAAAAAAAg/9oACAEBAAY/Al//xAAYEAADAQEAAAAAAAAAAAAAAAAAAREQIf/aAAgBAQABPyGp9EEWXP/aAAwDAQACAAMAAAAQmy//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/ED//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/ED//xAAcEAEAAgMAAwAAAAAAAAAAAAABABEhMUFxgdH/2gAIAQEAAT8QoC6eX5BHVeog4pl4CCXkd8n/2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/a1a1c9ea0582ab55121855fe9dee1d23/e4706/Davinci_Resolve_11.avif 230w", "/en/static/a1a1c9ea0582ab55121855fe9dee1d23/48441/Davinci_Resolve_11.avif 403w"],
              "sizes": "(max-width: 403px) 100vw, 403px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/a1a1c9ea0582ab55121855fe9dee1d23/a0b58/Davinci_Resolve_11.webp 230w", "/en/static/a1a1c9ea0582ab55121855fe9dee1d23/ca1b5/Davinci_Resolve_11.webp 403w"],
              "sizes": "(max-width: 403px) 100vw, 403px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/a1a1c9ea0582ab55121855fe9dee1d23/e83b4/Davinci_Resolve_11.jpg 230w", "/en/static/a1a1c9ea0582ab55121855fe9dee1d23/c80d3/Davinci_Resolve_11.jpg 403w"],
              "sizes": "(max-width: 403px) 100vw, 403px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/a1a1c9ea0582ab55121855fe9dee1d23/c80d3/Davinci_Resolve_11.jpg",
              "alt": "Windows Time Lapse Creator",
              "title": "Windows Time Lapse Creator",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/FAQs/"
        }}>{`FAQs`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9420WQHD/"
            }}>{`IN-9420 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9408WQHD/"
            }}>{`IN-9408 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8415WQHD/"
            }}>{`IN-8415 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8403WQHD/"
            }}>{`IN-8403 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8401WQHD/"
            }}>{`IN-8401 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9020FHD/"
            }}>{`IN-9020 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9010FHD/"
            }}>{`IN-9010 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9008FHD/"
            }}>{`IN-9008 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8015FHD/"
            }}>{`IN-8015 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8003FHD/"
            }}>{`IN-8003 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8001FHD/"
            }}>{`IN-8001 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-7011HD/"
            }}>{`IN-7011 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6014HD/"
            }}>{`IN-6014 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6012HD/"
            }}>{`IN-6012 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6001HD/"
            }}>{`IN-6001 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-5907HD/"
            }}>{`IN-5907 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-5905HD/"
            }}>{`IN-5905 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/VGA_Series/"
            }}>{`VGA Series`}</a></li>
        </ul>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      